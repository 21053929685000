import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';
import { Row } from 'react-bootstrap';

import '../../generic-page.scss';
import './reroll.scss';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_reroll.png"
            alt="Who to reroll for"
          />
        </div>
        <div className="page-details">
          <h1>Reroll</h1>
          <h2>
            How to reroll and start strong in Counter Side to make your life
            easier.
          </h2>
          <p>
            Last updated: <strong>14/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content reroll">
        <SectionHeader title="What is Rerolling and why is it worth doing?" />
        <p>
          Rerolling is the process of creating new accounts over and over again
          until you have a desirable start. The main purpose of rerolling in
          CounterSide is to start your account with an awakened (ASSR) unit.
          ASSRs are powerful units obtained from a separate banner with its own
          currency which is more sparse than the normal pull currency. It takes
          roughly two and a half months to obtain enough pull currency to pity
          an ASSR, so starting out with one can save a ton of resources.
        </p>
        <p>
          This guide will walk you through the process of rerolling and
          navigating the beginner banners so you can set yourself up for
          success. At its core, to reroll you will be:
        </p>
        <ul>
          <li>Getting 2750 quartz through tutorial rewards.</li>
          <li>Buying the weekly Classified Recruitment Quartz Package.</li>
          <li>Performing 11 pulls on the desired ASSR banner.</li>
          <li>Resetting your account if you didn’t get what you wanted.</li>
        </ul>
        <p>
          <strong>Disclaimer</strong>
        </p>
        <p>
          Rerolling can be very time-consuming and isn’t necessary if you don’t
          care to start with an ASSR. Each reroll takes roughly five minutes if
          you skip the story.
        </p>
        <SectionHeader title="Steps to Reroll" />
        <h4>1. Create a Guest account</h4>
        <p>
          Using a guest account will allow you to quickly reset your account if
          you don’t get what you want initially. You can’t create a guest
          account on Steam so it is advised to use the mobile client (ideally on
          an emulator) for this process.
        </p>
        <h4>2. Start the Tutorial</h4>
        <p>The game will hold your hand until stage 1-1-4.</p>
        <h4>3. New Ceo Favored Recruitment</h4>
        <StaticImage
          src="../../../images/counterside/generic/reroll_newceo.webp"
          alt="New CEO banner"
        />
        <p>
          You will be given 1,000 quartz to do 10 pulls on the New Ceo Favored
          Recruitment Banner. This banner is discounted and will guarantee an
          SSR from a small pool (listed below) in 30 or less pulls, but we will
          ignore it for now. <strong>By force restarting the game</strong>, you
          will skip the tutorial pull prompt, saving you 1,000 quartz for the
          next step.
        </p>
        <p>Favored Recruitment unit pool:</p>
        <div className="employee-container">
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="elizabeth-pendragon" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
          <Employee slug="esterosa-de-chevalier" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
        </div>
        <h4>4. Continue the Tutorial</h4>
        <p>
          Completing the first Guide Mission will net you an additional 1,750
          quartz among other helpful account progression resources.
        </p>
        <h4>5. Enter the Recruit Screen</h4>
        <StaticImage
          src="../../../images/counterside/generic/reroll_n_5.webp"
          alt="Reroll guide"
        />
        <p>
          Clicking on the recruit button will bring up the Selective Recruitment
          Banner. This is a unique banner that allows you to “reroll” up to 30
          10-pulls from a limited pool of SSRs.{' '}
          <strong>You are guaranteed 2 SSRs every 10-pull</strong>. The game
          will force you to do your first attempt on this banner, after which
          you want to exit by pressing the button on the top left. We will
          revisit this banner later.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <h4>
          6. Go to Classified Recruitment and purchase Classified Recruitment
          Tickets
        </h4>
        <p>
          On the left side of your screen you will be able to see a few tabs of
          banners. Click on “Classified Recruitment” and then purchase the
          “Classified Recruitment Quartz Package” on the bottom right of the
          screen for 2,750 quartz. This will give you 11 pulls towards an
          awakened banner of your choosing.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/reroll_n_1.webp"
          alt="Reroll guide"
        />
        <br />
        <StaticImage
          src="../../../images/counterside/generic/reroll_n_2.webp"
          alt="Reroll guide"
        />
        <h4>7. Choose a unit to pull for</h4>
        <p>
          Not all ASSRs will be available to pull at the same time. To find the
          ideal reroll target given the banners available at the time you’re
          rerolling, you can cross-reference the available banners with the
          Prydwen unit tier list.
        </p>
        <p>
          If you don’t care about unit performance, feel free to pull whoever
          you like. That said,{' '}
          <strong>
            DO NOT pull for Awakened Hilde, as the game gives you a free copy of
            her for clearing chapter 5 of the main story
          </strong>
          . A few examples of powerhouse ASSRs are as follows:
        </p>
        <div className="employee-container">
          <Employee slug="awakened-shin-jia" mode="icon" enablePopover />
          <Employee slug="awakened-sigma" mode="icon" enablePopover />
          <Employee slug="curian" mode="icon" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Awakened Shin Jia</strong> - Awakened Shin Jia (AJia) is a 5
            cost supporter who provides a combination of offensive and defensive
            buffs for your team. She costs more than your average supporter, but
            in turn provides a wide spread of utility and more potent buffs than
            would be found on other supporters. By micromanaging AJia’s kit
            mechanics, AJia can provide the largest ATK and CRIT buffs from a
            single unit in the game. On top of that, she also provides
            consistent shielding and some healing. This makes her extremely
            prevalent across all PVE content, and also gives her a niche in PVP.
          </li>
          <li>
            <strong>Awakened Sigma</strong> - Awakened Sigma (ASigma) is a melee
            damage dealer with incredible damage and survivability. ASigma sees
            frequent use in both PVE and PVP and can single-handedly carry new
            accounts deep into the game with how many roles she covers as a
            unit. ASigma’s tower provides her a strong self-regeneration effect
            that gives her staying power as a frontline unit, and when destroyed
            throws her into a berserk state, granting powerful offensive
            self-buffs. She also boasts the highest ultimate skill multiplier in
            the game. All these combine to create a self-healing frontline tank
            with more damage capacity than the majority of this game’s dedicated
            damage dealers.
          </li>
          <li>
            <strong>Curian</strong> - Curian is a backline damage dealer DPS
            monster who provides extremely high DPS for his cost, while also
            providing some of the highest burst damage in the game. He is an
            easy fit into teams and finds widespread use across almost all
            content in the game, and especially finds value in late game PVE
            game modes where he can best flex his massive damage. Curian’s
            singular focus on dealing damage extends beyond his slew of damage
            self-buffs and into his team “utility”. His passive resets allied
            Wardens’ empowered basics on special skill casts. When used
            together, Curian can push the team’s total burst damage to absurd
            heights.
          </li>
        </ul>
        <h4>8a. Reset Your Account</h4>
        <p>
          If you got the ASSR, congrats! Skip this step and go to 8b instead. If
          you didn’t receive an ASSR, reset your account. To do this:
        </p>
        <ul>
          <li>
            Click “Settings” (the cog icon) located on the top right of the
            screen
          </li>
          <li>
            Head to “Manage Account” and press the “Reset Server” button. This
            will delete your account and allow you to repeat steps 1-7 again
            until you get what you want.
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/reroll_n_3.webp"
          alt="Reroll guide"
        />
        <br />
        <StaticImage
          src="../../../images/counterside/generic/reroll_n_4.webp"
          alt="Reroll guide"
        />
        <h4>8b. Progress until you have more quartz</h4>
        <p>
          Continue to progress the game until you have enough quartz for the New
          CEO Favored Recruitment Banner (seen in Step 3).
        </p>
        <p>
          If there are any coupon codes available, now would be a good time to
          input them (Settings &gt; Manage Account &gt; Enter Coupon).
        </p>
        <p>
          If possible, you can link your Steam account for 150 quartz. A
          walkthrough of this process can be found{' '}
          <a
            href="https://steamdb.info/patchnotes/9414178/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          Of the available characters in the pool, you would want Gaeun or
          Chifuyu. However, in the grand scheme of things it doesn’t really
          matter.
        </p>
        <div className="employee-container">
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
        </div>
        <p>After you receive your guaranteed SSR, move on to the next step.</p>
        <h4>9. Selective Recruitment</h4>
        <p>
          Return to the selective recruitment banner to do your rolls. A list of
          notable SSRs available from the pool has been provided below. This
          pool has overlap with the Favored Recruitment banner. To avoid pulling
          a dupe, we saved this banner until now.{' '}
        </p>
        <p>
          There are multiple extremely strong SSRs you can get from this banner,
          but do not worry even though you can only receive two. Completing your
          Guide Missions will net you an additional six SSR selectors to get the
          important units you missed from this banner.
        </p>
        <p>
          The SSRs are the most important part of this banner. SRs are much
          easier to obtain and are often farmable.
        </p>
        <p>High priority units from the Selective Recruitment Pool:</p>
        <div className="employee-container">
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="sky-layfield" mode="icon" enablePopover />
          <Employee slug="nanahara-chinatsu" mode="icon" enablePopover />
          <Employee slug="kaci-bins" mode="icon" enablePopover />
          <Employee slug="alex" mode="icon" enablePopover />
        </div>
        <p>
          Nanahara Chinatsu, Kaci Bins, and Alex are valuable because they have
          extremely strong “rearms”. The Rearmament system overhauls a unit and
          gives them a new kit. To rearm a unit, you need to own a copy of the
          base version. Additional information can be found here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Rearm system"
            link="/counter-side/guides/rearm-system"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_rearm.png"
                alt="Rearm system"
              />
            }
          />
        </Row>
        <p>Here's an example of a good selective recruitment 10- pull:</p>
        <StaticImage
          src="../../../images/counterside/generic/reroll_n_6.webp"
          alt="Reroll guide"
        />
        <h4>10. Next Steps</h4>
        <p>
          You’re done! If you haven’t already linked your account, don’t forget
          to do so. The Guide Missions are an excellent way to ease yourself
          into the game’s various systems. Welcome to Counter:Side!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
      </div>
    </DashboardLayout>
  );
};

export default CSRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll guide | Counter Side | Prydwen Institute"
    description="How to reroll and start strong in Counter Side to make your life easier."
    game="cs"
  />
);
